import React from 'react'
import {createRoot} from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import {BrowserRouter} from 'react-router-dom'
import App from './App'
import GraphQLProvider from './provider/GraphQLProvider'
import TanstackQueryProvider from './provider/TanstackQueryProvider'
import AuthProvider from './provider/AuthProvider'
import AxiosDefaults from './hoc/AxiosDefaults'
import OnlineStatusProvider from './provider/OnlineStatusProvider'
import ErrorBoundaryMessage from './components/Plugs/ErrorBoundaryMessage'
import {ErrorBoundary} from 'react-error-boundary'
import {QueryParamProvider} from 'use-query-params'
import {ReactRouter6Adapter} from 'use-query-params/adapters/react-router-6'
import {HelmetProvider} from 'react-helmet-async'
import ImageFormatSupportProvider from './provider/ImageFormatSupportProvider'
import {Provider as AtomProvider} from 'jotai'
import queryString from 'query-string'


// import 'flatpickr/dist/flatpickr.min.css' // todo попробовать стили календаря всунуть в конкретные компоненты и посмотреть, увеличится ли от этого размер билда
// import './css/flatpickr.css'
//
// import 'react-image-gallery/styles/css/image-gallery.css'
// import './css/image-gallery.scss'
//
// import './css/Around/scss/theme.scss'


const root = createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        {/*<Provider store={store}>*/}
        <AtomProvider>
            <OnlineStatusProvider>
                <ImageFormatSupportProvider>
                    <TanstackQueryProvider>
                        <AuthProvider>
                            <AxiosDefaults/>
                            <GraphQLProvider>
                                <ErrorBoundary fallback={<ErrorBoundaryMessage/>}>
                                    <QueryParamProvider
                                        adapter={ReactRouter6Adapter}
                                        options={{
                                            // updateType: 'push',
                                            removeDefaultsFromUrl: true,
                                            searchStringToObject: queryString.parse,
                                            objectToSearchString: queryString.stringify,
                                            // params: {
                                            //     region: StringParam,
                                            //     subtypes: DelimitedArrayParam
                                            // }
                                            includeKnownParams: true,
                                            includeAllParams: true
                                        }}
                                    >
                                        <HelmetProvider>
                                            <App/>
                                            {/*<Helmet>*/}
                                            {/*    <title>{TITLE}</title>*/}
                                            {/*    <meta name="description" content={DESCRIPTION}/>*/}
                                            {/*    <meta property="og:title" content={TITLE}/>*/}
                                            {/*    <meta property="og:description" content={DESCRIPTION}/>*/}
                                            {/*</Helmet>*/}
                                        </HelmetProvider>
                                    </QueryParamProvider>
                                </ErrorBoundary>
                            </GraphQLProvider>
                        </AuthProvider>
                    </TanstackQueryProvider>
                </ImageFormatSupportProvider>
            </OnlineStatusProvider>
        </AtomProvider>
        {/*</Provider>*/}
    </BrowserRouter>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
