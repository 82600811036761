import {atom} from 'jotai'
import {atomWithStorage} from 'jotai/utils'


// Открыть/закрыть меню фильтра домов
export const housesFilterShowAtom = atom(false)

// Показывать/скрывать кнопку открытия меню фильтра домов в шапке
export const housesFilterBtnShowAtom = atom(false)


// Открыть/закрыть меню фильтра places
export const placesFilterShowAtom = atom(false)

// Показывать/скрывать кнопку открытия меню фильтра places в шапке
export const placesFilterBtnShowAtom = atom(false)


// Показывать кнопку прокрутки к блоку "Задати питання" (контакты) в шапке
export const showHeaderPhoneButton = atom() // параметр - ID элемента, к которому нужно прокрутить


export const checkInAtom = atomWithStorage('checkIn', undefined, undefined, {getOnInit: true})
export const checkOutAtom = atomWithStorage('checkOut', undefined, undefined, {getOnInit: true})

export const customerModeAtom = atomWithStorage('isCustomerMode', false, undefined, {getOnInit: true})

// Экземпляр календаря в блоке бронирования недвижимости. Применяется для открытия/пролистывания к календарю
export const houseFlatpickrAtom = atom()


export const dealTypeAtom = atom(undefined)

export const clientIdAtom = atom(undefined)

