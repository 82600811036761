import React, {Suspense} from 'react'
import {useAuth} from './hook/useAuth'
import LoaderDots from './components/Elements/LoaderDots'
import {useAtomValue} from 'jotai/index'
import {customerModeAtom} from './atom-store/atomStore'
import {maintainModeAtom} from './atom-store/maintainModeAtom'

// import 'flatpickr/dist/flatpickr.min.css' // todo попробовать стили календаря всунуть в конкретные компоненты и посмотреть, увеличится ли от этого размер билда
// import './css/flatpickr.css'
// import 'react-image-gallery/styles/css/image-gallery.css'
// import './css/image-gallery.scss'
// import './css/Around/scss/theme.scss'


const AuthenticatedRouting = React.lazy(() => import('./AuthenticatedRouting'))
const PublicRouting = React.lazy(() => import('./PublicRouting'))
const Maintain = React.lazy(() => import('./components/Plugs/Maintain'))


const App = () => {

    const isCustomerMode = useAtomValue(customerModeAtom)
    const isMaintain = useAtomValue(maintainModeAtom)
    const {isLoading, token, isAuthorized} = useAuth()


    if (isLoading) return <LoaderDots/>

    if (isMaintain) {
        return (
            <Suspense fallback={<LoaderDots/>}>
                <Maintain/>
            </Suspense>
        )
    }

    return (
        <Suspense fallback={<LoaderDots/>}>
            {
                (!token || isCustomerMode) ?
                    <PublicRouting/> :
                    (isAuthorized ? <AuthenticatedRouting/> : null)
            }
        </Suspense>
    )

}

export default React.memo(App)
