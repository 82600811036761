import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {API, AUTH_TOKEN, BEARER, ROUTES} from '../config'
import axios from 'axios'
import {useQuery} from '@tanstack/react-query'
import {useLocation, useNavigate} from 'react-router-dom'



const AuthProvider = ({children}) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [token, setToken] = useState(localStorage.getItem(AUTH_TOKEN))
    const [user, setUser] = useState()


    const [stringify, setStringify] = useState(null)


    useEffect(() => {
        if (token) {
            import('qs').then(module => {
                setStringify(() => module.stringify)
            })
        }
    }, [token])


    const query = token && stringify ?
        stringify(
            {
                fields: ['id', 'username', 'email'],
                populate: {
                    contact: {fields: 'id'},
                    role: {fields: ['id', 'name']},
                    recipient: {fields: ['id']},
                    regions: {fields: ['id']}
                }
            },
            {
                encodeValuesOnly: true // prettify URL
            }
        ) : null

    const queryStr = query ? `?${query}` : ''


    const {isInitialLoading, isError, error} = useQuery({
        queryKey: ['me', {token, query}],
        queryFn: () => axios.get(`${API}/users/me${queryStr}`, {
            headers: {
                Authorization: `${BEARER} ${token}`
            }
        }),
        retry: 0,
        onSuccess: response => {
            setUser(response.data)
        },
        enabled: Boolean(token && query)
    })


    const isAuthorized = Boolean(user)
    const isLoading = isInitialLoading && !isAuthorized


    const logOut = useCallback(() => {
        setToken(undefined)
        setUser(undefined)
        navigate(`/${ROUTES.LOGIN}`, {state: {fromPage: location?.pathname}})
    }, [location?.pathname, navigate])


    useEffect(() => {
        if (token) {
            localStorage.setItem(AUTH_TOKEN, token)
        } else {
            localStorage.removeItem(AUTH_TOKEN)
        }
    }, [token])


    const value = useMemo(() => ({
        user,
        token,
        isLoading,
        isError,
        isAuthorized,
        error,
        setUser,
        setToken,
        logOut
    }), [user, token, isLoading, isError, isAuthorized, error, setUser, setToken, logOut])


    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default React.memo(AuthProvider)




