import {atom} from 'jotai'
import isOnline from 'is-online'
import isReachable from 'is-reachable'
import {BACKEND_URL} from '../config'


export const maintainModeAtom = atom(false)

export const turnMaintainModeOffAtom = atom(null, (get, set) => {
    set(maintainModeAtom, false)
})

export const turnMaintainModeOnAtom = atom(null, async (get, set) => {
    const online = await isOnline()
    const isBackendAvailable = await isReachable(BACKEND_URL)
    if (online && !isBackendAvailable) {
        set(maintainModeAtom, true)
    }
})
